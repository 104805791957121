
import { 
    getData
} from '@/store/config/method'


const initialState = () => ({
    message: null,
    status: 0,
    unit: {
        PAUD: {},
        SD: {},
        SMP: {},
        SMA: {},
    },
    facility: {
        PAUD: [],
        SD: [],
        SMP: [],
        SMA: []
    },
    article: {
        prestasi: {
            PAUD: [],
            SD: [],
            SMP: [],
            SMA: []
        },
        pengumuman: {
            PAUD: [],
            SD: [],
            SMP: [],
            SMA: []
        },
        blog: {
            PAUD: [],
            SD: [],
            SMP: [],
            SMA: []
        },
    },
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getUnit: state => (key) => state.unit[key] || {},
    getFacility: state => (key) => state.facility[key] || [],
    getArticle: state => (key, edu_level) => state.article[key][edu_level] || [],
}

const actions = {
    async getUnitData({
        commit
    }, key){
        try {
            const url = `/edu-level-detail/${key}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_UNIT_DATA', {value: response.data.data, key});
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getFacilityData({
        commit
    }, key){
        try {
            const url = `/facility/unit`
            const response = await getData('api',url, {params: {edu_level_short_name: key}});
            commit('SET_RESPONSE', response.data);
            commit('SET_FACILITY_DATA', {value: response.data.data, key});
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getArticleData({
        commit
    }, {key, filter, edu_level, limit = 4}){
        try {
            const response = await getData('api','/article-website/limit',{params: {filter, edu_level_id: edu_level, limit}});
            commit('SET_RESPONSE', response.data);
            commit('SET_ARTICLE_DATA', {value: response.data.data, key, edu_level});
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_UNIT_DATA(state, {value, key}) {
        state.unit[key] = value
    },
    SET_FACILITY_DATA(state, {value, key}) {
        state.facility[key] = value
    },
    SET_ARTICLE_DATA(state, {value, key, edu_level}) {
        state.article[key][edu_level] = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}