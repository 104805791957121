
import { 
    getData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    category_name: null,
    category_id: null,
    sort_name: null,
    sort_id: null,
    search: null,
}

const initialState = () => ({
    message: null,
    status: 0,
    disc: {
        prestasi: 'ACHIEVEMENT',
        blog: 'BLOG',
        pengumuman: 'ANNOUNCEMENT'
    },
    request: {
        prestasi: {...requestInit},
        blog: {...requestInit},
        pengumuman: {...requestInit}
    },
    article: {
        prestasi: {},
        blog: {},
        pengumuman: {}
    },
    articles: {
        prestasi: [],
        blog: [],
        pengumuman: []
    },
    meta: {
        prestasi: {},
        blog: {},
        pengumuman: {}
    },
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getArticle: state => (key) => state.article[key] || {},
    getArticles: state => (key) => state.articles[key] || [],
    getRequest: state => (key) => state.request[key] || {...requestInit},
    getMeta: state => (key) => state.meta[key] || {},
}

const actions = {
    resetRequest({commit}, key){
        commit('SET_REQUEST',{key, data: {...requestInit}})
    },
    setRequest({commit}, {key, data}){
        commit('SET_REQUEST',{key, data})
    },
    async getArticleData({
        commit
    }, {key, slug}){
        try {
            const url = `/article/${slug}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_ARTICLE_DATA', {data: response.data.data, key});
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getArticlesData({
        commit, state
    }, key){
        try {
            const response = await getData('api','/article', {params: {
                ...state.request[key],
                filter: state.disc[key]
            }});
            commit('SET_RESPONSE', response.data);
            commit('SET_ARTICLES_DATA', {res: response.data.data, key});
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_ARTICLE_DATA(state, {data, key}) {
        state.article[key] = data
    },
    SET_REQUEST(state, {data, key}) {
        state.request[key] = data
    },
    SET_ARTICLES_DATA(state, {res, key}) {
        const articles = state.meta[key].current_page < res.current_page ? state.articles[key] : []
        state.articles[key] = [...articles, ...res.data]
        state.meta[key] = {
            current_page: res.current_page,
            from: res.from,
            last_page: res.last_page,
            per_page: res.per_page,
            to: res.to,
            total: res.total
        }
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}