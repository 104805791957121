<template>
    <div class="r-keep-view">
        <HomeNavbar />
        <div class="r-page">
            <router-view></router-view>
        </div>
        <HomeFooter />
    </div>
</template>
<script>
import HomeNavbar from '@/components/home/Navbar'
import HomeFooter from '@/components/home/Footer'

export default {
    components: {
        HomeNavbar,
        HomeFooter,
    },
}
</script>