
import { 
    getData, postData, destroyData
} from '@/store/config/method'

const suggestionItemInit = {
    id: null,
    subject: null,
    content: '<p></p>',
    edu_level_id: null,
    edu_level: null
}
const faqRequestInit = {
    filter: null,
    filter_label: 'Paling Sesuai',
    search: null,
    page: 1
}
const initialState = () => ({
    message: null,
    status: 0,
    faqRequest: {...faqRequestInit},
    faq: [],
    faqMeta: {},
    faqDetail: {},
    suggestions: [],
    suggestionItem: {...suggestionItemInit}
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getFaqRequest: state => state.faqRequest,
    getFaq: state => state.faq || [],
    getFaqMeta: state => state.faqMeta || [],
    getFaqDetail: state => state.faqDetail || {},
    getSuggestions: state => state.suggestions || [],
    getSuggestionItem: state => state.suggestionItem
}

const actions = {
    resetFaqRequestInit({commit}){
        commit('SET_FAQ_REQUEST', {...faqRequestInit})
    },
    resetSuggestionItem({commit}){
        commit('SET_SUGGESTION_ITEM', {...suggestionItemInit})
    },
    setSuggestionItem({commit}, value){
        commit('SET_SUGGESTION_ITEM', {...value})
    },
    async getSuggestionsData({
        commit
    }){
        try {
            const url = `/suggestions-criticism`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_SUGGESTIONS_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async store({
        commit
    }, data) {
        try {
            const payload = {...data}
            delete payload.id
            const response = await postData('api','/suggestions-criticism',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async destroy({
        commit
    }, data) {
        try {
            const url = `/suggestions-criticism/${data.id}`
            const response = await destroyData('api',url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getFaqData({
        commit, state
    }){
        try {
            const url = `/faqs`
            const response = await getData('api',url, {params: state.faqRequest});
            commit('SET_RESPONSE', response.data);
            commit('SET_FAQ_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getFaqDetailData({
        commit
    }, faq_id){
        try {
            const url = `/faqs/${faq_id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_FAQ_DETAIL_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_FAQ_REQUEST(state, value) {
        state.faqRequest = value
    },
    SET_SUGGESTION_ITEM(state, value) {
        state.suggestionItem = value
    },
    SET_FAQ_DETAIL_DATA(state, value) {
        state.faqDetail = value
    },
    SET_SUGGESTIONS_DATA(state, value) {
        state.suggestions = value
    },
 
    SET_FAQ_DATA(state, response) {
        const reqPage = state.faqRequest.page
        let faq = response.data
        if(reqPage > 1){
            faq = [...state.faq, ...response.data]
        }
        state.faq = faq
        state.faqMeta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}