import { routerFilter } from '@/helpers/routes'

function loadSiakad(view) {
    return () => import( 
        /* webpackChunkName: "siakad-[request]" */
        /* webpackMode: "lazy" */
         `@/views/siakad/${view}.vue`)
}

const routes = [
    {
        path: '',
        name: 'Siakad',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('Siakad')
    },
    {
        path: 'pengaturan-akun',
        name: 'ParentDetail',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('ParentDetail')
    },
    {
        path: 'pendidikan-anak',
        name: 'ChildrenHistory',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('ChildrenHistory')
    },
    {
        path: 'pendidikan-anak/:user_id',
        name: 'ChildrenDetail',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('ChildrenDetail')
    },
    {
        path: 'pendidikan-anak/:user_id/daftar-pelajaran',
        name: 'ChildrenSubjectList',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('SubjectList')
    },
    {
        path: 'pendidikan-anak/:user_id/daftar-pelajaran/:classroom_subject_id',
        name: 'ChildrenAttendanceAndAgenda',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('AttendanceAndAgenda')
    },
    {
        path: 'pendidikan-anak/:user_id/nilai-rapor',
        name: 'ChildrenReport',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('Report')
    },
    {
        path: 'pendidikan-anak/:user_id/riwayat-kelas',
        name: 'ChildrenClassHistory',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('ClassHistory')
    },
    {
        path: 'pendidikan-anak/:user_id/riwayat-kelas/:classroom_subject_id',
        name: 'ChildrenAttendanceAndAgendaHistory',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('AttendanceAndAgenda')
    },
    {
        path: 'status-pendaftaran',
        name: 'AdmissionChildren',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('AdmissionChildren')
    },
    {
        path: 'pusat-bantuan',
        name: 'HelpCenter',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('HelpCenter')
    },
    {
        path: 'pusat-bantuan/kritik-saran',
        name: 'Suggestions',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('Suggestions')
    },
    {
        path: 'pusat-bantuan/:faq_id',
        name: 'FaqDetail',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('FaqDetail')
    },
    {
        path: 'daftar-pelajaran',
        name: 'SubjectList',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('SubjectList')
    },
    {
        path: 'riwayat-kelas',
        name: 'ClassHistory',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('ClassHistory')
    },
    {
        path: 'riwayat-kelas/:classroom_subject_id',
        name: 'AttendanceAndAgendaHistory',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('AttendanceAndAgenda')
    },
    {
        path: 'daftar-pelajaran/:classroom_subject_id',
        name: 'AttendanceAndAgenda',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('AttendanceAndAgenda')
    },
    {
        path: 'nilai-rapor',
        name: 'Report',
        meta: {
            requiresAuth: 1,
            role: ['parent','student'],
            title: 'Siakad Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 0
        },
        component: loadSiakad('Report')
    }
]

export default routerFilter(routes)