import { routerFilter } from '@/helpers/routes'
import landingPage from '@/views/home/LandingPage'

function loadHome(view) {
    return () => import( 
        /* webpackChunkName: "home-view-[request]" */ 
        /* webpackMode: "lazy" */ 
        `@/views/home/${view}.vue`)
}

const routes = [
    {
        path: '',
        name: 'LandingPage',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 1,
            robots: 1
        },
        component: landingPage,
    },
    {
        path: '/unit-pendidikan/:slug',
        name: 'UnitProfile',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 0,
            robots: 1
        },
        component: loadHome('UnitProfile'),
    },
    {
        path: '/ikatan-alumni-masehi-kudus',
        name: 'Ikamaku',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Ikatan Alumni Masehi Kudus',
            description: 'Ikatan Alumni Masehi Kudus',
            routeMeta: 1,
            robots: 1
        },
        component: loadHome('Ikamaku'),
    },
    {
        path: '/struktur-organisasi',
        name: 'OrganizationStructure',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Struktur Organisasi Sekolah Masehi Kudus',
            description: 'Struktur Organisasi Sekolah Masehi Kudus',
            routeMeta: 1,
            robots: 1
        },
        component: loadHome('OrganizationStructure'),
    },
    {
        path: '/profil-yayasan',
        name: 'FoundationProfile',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Profile Yayasan Sekolah Masehi Kudus',
            description: 'Profile Yayasan Sekolah Masehi Kudus',
            routeMeta: 1,
            robots: 1
        },
        component: loadHome('FoundationProfile'),
    },
    {
        path: '/:contentType(blog|prestasi|pengumuman)',
        name: 'ContentList',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 0,
            robots: 1
        },
        component: loadHome('ContentList'),
    },
    {
        path: '/:contentType(blog|prestasi|pengumuman)/:slug',
        name: 'ContentDetail',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Sekolah Masehi Kudus',
            description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Mashi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus),',
            routeMeta: 0,
            robots: 1
        },
        component: loadHome('ContentDetail'),
    },
    {
        path: '/kontak',
        name: 'Contact',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Kontak Sekolah Masehi Kudus',
            description: 'Kontak Sekolah Masehi Kudus',
            routeMeta: 1,
            robots: 1
        },
        component: loadHome('Contact'),
    }
]

export default routerFilter(routes)