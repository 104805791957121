
import { 
    postData, getData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
    edu_level: 'Semua Unit',
    edu_level_id: null,
    status_label: 'Semua Status',
    status: null
}

const itemInit = {
    id: null,
    name: null,
    nik: null,
    kk_number: null,
    akta_registration_number: null,
    religion_id: null,
    religion: null,
    gender: null,
    location_id: null,
    location: null,
    dob: null,
    phone_number: null,
    address: null,
    transportation_id: null,
    transportation: null,
    nisn: null,
    weight: null,
    height: null,
    is_using_kps_kph: false,
    is_had_kip: false,
    kip_number: null,
    edu_level_id: null,
    edu_level: null,
    nis: null,
    email: null,
    child_order: 1,
    is_graduated: false,
    admission_schedule_id: null,
    education_level_class_id: null,
    education_level_class: null,
    user_id: null,
}

const admissionInit = {
    id:	null,
    name: null,
    short_name:	null,
    admission_schedule_id: null,
    content: null,
    date_begin:	null,
    date_end: null,
    status:	null,
    edu_level_slot_name: null,
    education_class: []
}
const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    request: {...requestInit},
    item: {...itemInit},
    admission: {...admissionInit},
    list: [],
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data,
    getMeta: state => state.meta,
    getRequest: state => state.request,
    getItem: state => state.item,
    getList: state => state.list,
    getAdmission: state => state.admission || {}
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}, edu_level){
        const value = {
            ...itemInit,
            education_level_class_id: edu_level.id,
            education_level_class: edu_level.name,
            admission_schedule_id: edu_level.admission_schedule_id
        }
        commit('SET_ITEM',{...value})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    resetAdmission({commit}){
        commit('SET_ADMISSION',{...admissionInit})
    },
    setAdmission({commit}, value){
        commit('SET_ADMISSION',{...value})
    },
    async fetchData({
        commit, state
    }){
        try {
            const response = await getData('api','/management/my-children-data',{params: state.request});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getAllByAdmission({
        commit
    }, {parent_group_id, admission_schedule_id}){
        try {
            const response = await getData('api','/management/my-all-children-data',{params: {parent_group_id, admission_schedule_id}});
            commit('SET_RESPONSE', response.data);
            commit('SET_LIST', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async submit({
        commit
    }, data) {
        try {
            const url = `/management/my-children-data${data.user_id ? `/${data.user_id}` : ''}` 
            const response = await postData('api',url,data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_LIST(state, value) {
        state.list = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_ADMISSION(state, value){
        state.admission = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}