<template>
  <teleport to="#snackbar">
    <div class="r-snackbar" :class="!show ? 'r-snackbar-hide' : 'r-snackbar-show'"> 
        <div class="r-snackbar-box r-center-flex" :class="`r-${snackbar.type}-snackbarbg`">
            <div class="r-center-flex">
                <!-- <img :src="require(`@/assets/icons/toast/${snackbar.type}.svg`)" alt=""> -->
            </div>
            <div class="r-snackbar-text r-ml-10">
                <p>{{snackbar.text}}</p>
            </div>
            <div class="r-snackbar-action r-center-flex" v-if="snackbar.withclose">
                <div class="r-snackbar-button r-center-flex" @click.prevent="hideSnackbar()">
                    <img :src="require('@/assets/icons/black/close.svg')" alt="close_button">
                </div>
            </div>
        </div>
    </div>
  </teleport>
</template>
<script>
// Snackbar type :
// - success
// - error
// - alert
import { mapGetters, mapActions}  from 'vuex'
export default {
    watch: {
        show(){
            this.setTime()
        }
    },
    computed:{
        ...mapGetters({
            snackbar: 'snackbar/getSnackbar'
        }),
        show(){
            return this.snackbar.show
        }
    },
    data: () => ({
        hideSnackbarTime: null
    }),
    methods:{
        ...mapActions({
            hideSnackbar: 'snackbar/hideSnackbar'
        }),
        setTime() {
            clearTimeout(this.hideSnackbarTime);
            this.hideSnackbarTime = setTimeout( () => {this.$store.dispatch('snackbar/hideSnackbar')}, 2000);
        }
    }
}
</script>
<style scoped lang="scss">
.r-capitalize{
    text-transform: capitalize
}
.r-success-snackbarbg {
    background: var(--success-10);
}
.r-error-snackbarbg {
    background: var(--error-10);
}
.r-alert-snackbarbg {
    background: var(--warning-10);
}
.r-snackbar-show{
    bottom: 25px;
}
.r-snackbar-hide{
    bottom: -100%;
}
.r-snackbar{
    transition: all 500ms ease-out;
    position: fixed;
    min-height: 50px;
    z-index: 10000000000;
    .r-snackbar-box{
        margin: 0px auto;
        padding: 6px 12px;
        border-radius: 4px;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.32);
        overflow: hidden;
        .r-snackbar-text{
            margin-right: auto;
            font-style: normal;
            font-weight: 500;
            cursor: default;
        }
        .r-snackbar-action{
            display: flex;
            .r-snackbar-button{
                cursor: pointer;
                font-style: normal;
                font-weight: 600;
            }
        }
    }
}
@media screen and (min-width: 350px) {
   .r-snackbar{
        min-width: 300px;
        max-width: 300px;
        left: calc(50% - 150px);
   }
}
@media screen and (max-width: 350px) {
    .r-snackbar{
        min-width: 200px;
        max-width: 200px;
        left: calc(50% - 100px);
   }
}
@media screen and (max-width: 250px) {
    .r-snackbar{
        min-width: 150px;
        max-width: 150px;
        left: calc(50% - 75px);
   }
}

</style>