<template>
    <teleport to="head">
        <meta name="description" :content="description">
    </teleport>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      getMetaData: 'getMetaData'
    }),
    description() {
      return this.getMetaData.description
    }
  }
}
</script>