<template>
    <div class="r-box r-isExpand r-p-16">
        <div class="r-isRelative r-img-place r-mb-12">
            <img class="r-position-center r-isExpand" v-lazy="path" :alt="alt">
        </div>
        <span class="r-mb-8 r-secondary-text r-800 r-uppercase" v-if="label">{{label}}</span>
        <h5 class="r-600" v-if="title">{{title}}</h5>
        <span class="r-mt-4 r-base-50-text" v-if="createdAt">{{fullMonth(createdAt)}}</span>
    </div>
</template>
<script>
import { fullMonth } from '@/helpers/timeformat'

export default {
    props: {
        path: {
            default: require('@/assets/image/img.png')
        },
        alt: {
            default: 'Gambar'
        },
        label: {
            default: null
        },
        title: {
            default: null
        },
        createdAt: {
            default: null
        }
    },
    methods: {
        fullMonth(value){
            if(!value) return '-'
            return fullMonth(value)
        }
    }
}
</script>