<template>
    <section class="r-landing-banner">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :pagination="pagination"
            :autoplay="autoplay"
            :loop="true"
        >
            <swiper-slide v-for="(b,i) in banner" :key="`banner-${i}`">
                <div>
                    <a :href="b.link" class="r-center-flex r-banner r-isExpand"  rel="noreferrer noopener nofollow" target="_blank">
                        <div class="r-ptb-60">
                            <img class="r-isExpand" v-lazy="b.full_path" :alt="b.alt">
                        </div>
                        <img class="r-isExpand r-banner-bg" v-lazy="b.full_path" :alt="b.alt">
                    </a>    
                </div>
            </swiper-slide>
        </swiper>
    </section>
</template>
<script>
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import { mapGetters, mapActions} from 'vuex'

export default {
    components: {
      Swiper,
      SwiperSlide,
      
    },
    computed: {
        ...mapGetters({
            banner: 'landingPage/getBanner',
        }),
    },
    mounted(){
        this.initData()
    },
    data: () => ({
        imgIndex: 0,
        modules: [Pagination, Autoplay],
        pagination: { clickable: true, dynamicBullets: true },
        autoplay: { delay: 3500}
    }),
    methods: {
        ...mapActions({
            getBannerData: 'landingPage/getBannerData',
        }),
        async initData(){
            await this.getBannerData()
        },
        currentBanner(){
            if(this.banner.length == 0 || this.imgIndex >= this.banner.length) return {full_path: require('@/assets/image/pattern.png'), link: '/'}
            return this.banner[this.imgIndex]
        },
        gotoLink(link){
            window.open(link)
        },
        onSwiper(){

        },
        onSlideChange(){

        }
    }
}
</script>