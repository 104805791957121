<template>
    <div class="r-keep-view">
        <SiakadNavbar></SiakadNavbar>
        <div class="r-page">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import SiakadNavbar from './SiakadNavbar'
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        SiakadNavbar
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser'
        })
    },
    mounted(){
        this.refreshData()
    },
    methods: {
        ...mapActions({
            getMyAccount: 'auth/getMyAccount',
            getStudentDetail: 'auth/getStudentDetail',
        }),
        async refreshData() {
            await this.getMyAccount()
            const isInvalid = this.$store.getters[`auth/getIsInvalid`]
            if(isInvalid) this.$router.push({name: 'Logout'})
            if(this.user.role == 'student') await this.getStudentDetail()
        },
    }
}
</script>