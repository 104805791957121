import modules from './modules'
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';
import { createStore } from 'vuex'
import { getLocaleMonths } from '@/helpers/timeformat'

localForage.config({
  name        : 'sekolah-masehi-kudus',
  version     : 1.0,
  storeName   : 'website_cms', // Should be alphanumeric, with underscores.
});

const masterStorage1 = new VuexPersistence({
  key: 'master-data',
  storage: localForage,
  reducer: state => ({ locale: state.locale, dateMaster: state.dateMaster }),
  asyncStorage: true
})
const authStorage = new VuexPersistence({
  key: 'auth',
  storage: localForage,
  reducer: state => ({ auth: state.auth }),
  asyncStorage: true
})

export default createStore({
  state: {
    isLoggedIn: !!localStorage.getItem('schkey'),
    isSticky: false,
    metaData: {
        description: '',
        robots: 1,
    },
    isMobile: false,
    uploadLoading: 0,
    dateMaster: {
      dateList: [],
      monthList: [],
      yearList: [],
      ageLimit: 0,
      locale: null
    }
  },
  getters: {
    getMetaData: state => state.metaData,
    getisLoggedIn: state => state.isLoggedIn,
    getIsMobile: state => state.isMobile,
    getUploadLoading: state => state.uploadLoading,
    getDateList: state => state.dateMaster.dateList,
    getMonthList: state => state.dateMaster.monthList,
    getYearList: state => state.dateMaster.yearList,
    getIsSticky: state => state.isSticky
  },
  actions: {
    setIsSticky({commit}, value){
      commit("SET_IS_STICKY", value)
    },
    setDateList({commit, state}){
      if(state.dateMaster.dateList.length == 0){
        const date = [];
        for (let i = 1; i < 32; i++) {
            date.push(i);
        }
        commit('SET_DATE_LIST',date)
      }
    },
    setMonthList({commit, state}, locale){
      if(state.dateMaster.locale != locale){
        const months = getLocaleMonths(locale)
        commit('SET_MONTH_LIST', {months, locale})
      }
    },
    setYearList({commit, state}, limit){
      if(state.dateMaster.ageLimit != limit){
        const year = [];
        const today = new Date();
        const thisYear = today.getFullYear();
        for (let i = 0; i < limit; i++) {
            year.push(thisYear - i);
        }
        commit('SET_YEAR_LIST', {year, limit})
      }
    },
    setUploadLoading({commit}, value){
      commit('SET_UPLOAD_LOADING', value)
    },
    setIsMobile({commit}, value){
      const result = value < 800
      commit('SET_IS_MOBILE', result)
    },
    setIsLoggedIn({commit}, value){
      commit("SET_IS_LOGGED_IN", value)
    },
    setMetaTag({commit},{description = null, robots = 1}){
        commit("SET_META_TAG", {description, robots})
    },
    async logoutStorage({commit,state}){
      localStorage.clear();
      state.isLoggedIn = false
      const initial = modules
      Object.keys(initial).forEach(key =>  commit(`${key}/resetState`))
    }
  },
  mutations: {
    SET_DATE_LIST(state, value){
      state.dateMaster.dateList = value
    },
    SET_MONTH_LIST(state, data){
      state.dateMaster.monthList = data.months
      state.dateMaster.locale = data.locale
    },
    SET_YEAR_LIST(state, data){
      state.dateMaster.yearList = data.year
      state.dateMaster.ageLimit = data.limit
    },
    SET_UPLOAD_LOADING(state, value) {
      state.uploadLoading = value;
    },
    SET_IS_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_IS_MOBILE(state, value){
      state.isMobile = value
    },
    SET_IS_STICKY(state, value){
      state.isSticky = value
    },
    SET_META_TAG(state, metaTag){
      state.metaData = {
        ...metaTag
      }
    }
  },
  modules,
  plugins: [masterStorage1.plugin, authStorage.plugin]

})