const initialState = () => ({
    snackbar: {
        type: "success",
        text: "message",
        withclose: true,
        show: false
    },
})
const state = initialState
 
const getters = {
    getSnackbar: state => state.snackbar,
}
const actions = {
    showSnackbar({commit}, {
        type = "success",
        text = "message",
        withclose = true,
        show = true
      }){
        const value ={
          type: type,
          text: text,
          withclose: withclose,
          show: show
        }
      commit('SET_SNACKBAR', value);
    },
    hideSnackbar({commit, state}){
        const value ={
          ...state.snackbar,
          show: false
        }
        commit('SET_SNACKBAR', value);
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_SNACKBAR(state, value) {
        state.snackbar = value;
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}