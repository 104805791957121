
import { 
    getData
} from '@/store/config/method'

const initialState = () => ({
    message: null,
    status: 0,
    banner: [],
    facilities: [],
    articles: {
        prestasi: [],
        blog: [],
    },
    organizationStructures: [],
    edu_units: [],
    edu_unit_detail: {},
    accreditation: [],
    registration: {}
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getBanner: state => state.banner || [],
    getFacilities: state => state.facilities || [],
    getArticles: state => (key) => state.articles[key] || [],
    getOrganizationStructures: state => state.organizationStructures || [],
    getEduUnits: state => state.edu_units || [],
    getEduUnitDetail: state => state.edu_unit_detail || {},
    getAccreditation: state => state.accreditation || [],
    getRegistration: state => state.registration || {}
}

const actions = {
    async getAccreditationData({
        commit
    }){
        try {
            const response = await getData('api',`/edu-level/accreditation`);
            commit('SET_RESPONSE', response.data);
            commit('SET_ACCREDITATION_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getEduUnitDetailData({
        commit
    }, slug){
        try {
            const response = await getData('api',`/education-unit/home/${slug}`);
            commit('SET_RESPONSE', response.data);
            commit('SET_EDU_UNIT_DETAIL_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getEduUnitsData({
        commit
    }){
        try {
            const response = await getData('api','/education-unit/home');
            commit('SET_RESPONSE', response.data);
            commit('SET_EDU_UNITS_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getBannerData({
        commit
    }){
        try {
            const response = await getData('api','/slider');
            commit('SET_RESPONSE', response.data);
            commit('SET_BANNER_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getFacilitiesData({
        commit
    }){
        try {
            const response = await getData('api','/facility');
            commit('SET_RESPONSE', response.data);
            commit('SET_FACILITY_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getOrganizationStructuresData({
        commit
    }){
        try {
            const response = await getData('api','/organizational-structure');
            commit('SET_RESPONSE', response.data);
            commit('SET_ORG_STRUCTURE_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getArticlesData({
        commit
    }, {key, filter}){
        try {
            const response = await getData('api','/article-website/limit',{params: {filter}});
            commit('SET_RESPONSE', response.data);
            commit('SET_ARTICLES_DATA', {data: response.data.data, key});
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getRegistrationData({
        commit
    }){
        try {
            const response = await getData('api','/management/setting/home-registration');
            commit('SET_RESPONSE', response.data);
            commit('SET_REGISTRATION_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_ACCREDITATION_DATA(state, value) {
        state.accreditation = value
    },
    SET_EDU_UNIT_DETAIL_DATA(state, value) {
        state.edu_unit_detail = value
    },
    SET_EDU_UNITS_DATA(state, value) {
        state.edu_units = value
    },
    SET_BANNER_DATA(state, value) {
        state.banner = value
    },
    SET_FACILITY_DATA(state, value) {
        state.facilities = value
    },
    SET_ORG_STRUCTURE_DATA(state, value) {
        state.organizationStructures = value
    },
    SET_ARTICLES_DATA(state, {data, key}) {
        state.articles[key] = data
    },
    SET_REGISTRATION_DATA(state, value) {
        state.registration = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}