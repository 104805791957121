import authRoute from './authRoute'
import NotFound from '@/views/NotFound'
import { routerFilter } from '@/helpers/routes'
import homeLayout from '@/components/home/Layout'
import siakadLayout from '@/components/home/SiakadLayout'
import homeRoute from './homeRoute'
import siakadRoute from './siakadRoute'

const generateRoutes = async () => {
  const auth = await authRoute;
  const home = await homeRoute;
  const siakad = await siakadRoute;

  const routes = [
    {
      path: '', 
      meta: {
        requiresAuth: 2,
        role: []
      },
      component: homeLayout,
      children: [
        ...home
      ]
    },
    {
      path: '/siakad', 
      meta: {
        requiresAuth: 1,
        role: []
      },
      component: siakadLayout,
      children: [
        ...siakad
      ]
    },
    ...auth,
    {
      path: '/:pathMatch(.*)*', 
      name: 'Not Found',
      meta: {
        requiresAuth: 3,
        role: []
      },
      component: NotFound
    },
    {
      path: '/:pathMatch(.*)', 
      name: 'Bad Not Found',
      meta: {
        requiresAuth: 3,
        role: []
      },
      component: NotFound
    }
  ]

  return await routerFilter(routes)
}
export default generateRoutes()