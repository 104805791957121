
import { 
    getData
} from '@/store/config/method'

const initialState = () => ({
    message: null,
    status: 0,
    report: {},
    schoolYears: [],
    currentSemester: {}
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getReport: state => state.report || {},
    getSchoolYears: state => state.schoolYears,
    getCurrentSemester: state => state.currentSemester
}

const actions = {
    async getReportData({
        commit
    }, {edu_level_slot_id, user_id = null}){
        try {
            const url = user_id 
                ? `/management/my-children/${user_id}/report/${edu_level_slot_id}`
                : `/management/user-student/report/${edu_level_slot_id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_REPORT_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getSchoolYearsData({
        commit
    }){
        try {
            const url = `/management/school-years/all`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_SCHOOL_YEARS', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_REPORT_DATA(state, value) {
        state.report = value
    },
    SET_SCHOOL_YEARS(state, response) {
        state.schoolYears = response.schoolYears
        state.currentSemester = response.current
    },
    SET_ATTENDANCES_DATA(state, value) {
        state.attendances = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}