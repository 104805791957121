<template>
    <div class="r-container">
        <div class="r-center-flex r-mb-40">
            <h3 class="r-mr-auto">{{title}}</h3>
            <router-link :to="route" v-if="list.length == 4">
                <ButtonCustom type="textOnly" padding="0" class="r-plr-0">
                    <div class="r-center-flex">
                        <span class="r-isBold r-mr-10">
                            Lihat {{title}} Lainnya
                        </span>
                        <img :src="require('@/assets/icons/primary/arrow-right.svg')" alt="arrow-right">
                    </div>
                </ButtonCustom>
            </router-link>
        </div>
        <div class="r-flex r-columns r-isWrap">
            <div class="r-column r-column-4d" v-for="(b,i) in list" :key="`${title}-${i}`">
                <router-link :to="{name: 'ContentDetail', params: {contentType: this.type, slug: b.slug}}">
                    <Card 
                        :path="b.full_path"
                        :label="b.category_name"
                        :title="b.title"
                        :createdAt="b.created_at"
                    ></Card>
                </router-link>
            </div>
            
        </div>
    </div>
</template>
<script>
import Card from '@/components/helper/Card'
import ButtonCustom from '@/components/helper/Button'

export default {
    props: {
        title: {
            default: null
        },
        list: {
            default: []
        },
        type: {
            default: 'blog'
        },
        route: {
            default: () => ({
                name: 'LandingPage'
            })
        }
    },
    components: {
        Card,
        ButtonCustom
    },
}
</script>