<template>
  <div id="Login" class="r-vh-100-min r-isRelative" >
    <div class="r-container">
      <logo class="r-pt-16"></logo>
    </div>
    <div class="r-position-center r-isExpand r-regist-form" >
      <div class="r-p-16 r-wd-400-max r-isExpand r-mlr-auto">
        <div class="r-center-flex">
            <div class="r-center-text r-mlr-auto ">
              <div class="r-flex r-wd-80-max r-mlr-auto">
                <img class='r-isExpand' :src="require('@/assets/image/glass/Danger.png')" alt="Danger">
              </div>
              <h4 class="r-mtb-10">Halaman Tidak Ditemukan</h4>
              <p class="r-mb-10">Silahkan klik tombol di bawah untuk kembali ke halaman awal.</p>

                <router-link :to="generateTo()">
                  <ButtonCustom color="base-20" class="r-isExpand r-mt-20">
                      Kembali
                  </ButtonCustom>
                </router-link>
            </div>            
        </div>
      </div>
    </div>
    
    <div class="r-footer-dashboard">
      <p>
        Version 1.0 - Copyright © {{year()}} Sekolah Masehi Kudus
      </p>
    </div>
  </div>
</template>
<script>
import common from '@/mixins/common'
import logo from '@/components/helper/Logo'
const today = new Date;

export default {
    mixins: [common],
    components: {
      logo
    },
     methods:{
        year(){
          return today.getFullYear()
        },
        generateTo(){
          return {name: 'LandingPage'}
        }
    }
}
</script>
