<template>
    <teleport to="#modal">
        <div class="r-modal-black" @click.stop="bgClick">
            <div class="r-keep-view">
                <div class="r-container">
                    <div class="r-center-flex r-mt-20">
                        <ButtonCustom color="white" @click.prevent="closeModal()" class="r-ml-auto">
                            <div class="r-center-flex">
                                <img :src="require('@/assets/image/close.png')"  alt="close" class="r-mr-10" >
                                <span>Tutup</span>
                            </div>
                        </ButtonCustom>
                    </div>
                </div>
            </div>
            <div class="r-modal-body r-position-center" @click.stop="">
                <div class="r-modal-header">
                </div>
                <div class="r-modal-value">
                    <div class="r-center-flex">
                        <iframe :width="!isMobile ? '830' : '100%'" :height="!isMobile ? '450' : '250'" class="r-mlr-auto" :src="`https://www.youtube.com/embed/${youtubeId}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>
<script>
import common from '@/mixins/common'

export default {
    mixins: [common],
    emits: ['bgClick'],
    props: {
        youtubeId: {
            require: true
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal')
        },
        bgClick(){
            this.$emit('bgClick')
        },
    }
}
</script>