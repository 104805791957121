const initialState = () => ({
    crud: null,
    cropper: null
});
const state = initialState;

const getters = {
    getCrudModal: (state) => state.crud,
    getCropperModal: (state) => state.cropper
};
const actions = {
    setCrudModal({ commit }, value){
        commit('SET_CRUD_MODAL', value)
    },
    setCropperModal({ commit }, value){
        commit('SET_CROPPER_MODAL', value)
    },
};

const mutations = {
    SET_CRUD_MODAL(state, value){
        state.crud = value
    },
    SET_CROPPER_MODAL(state, value){
        state.cropper = value
    },
    resetState(state) {
        const initial = initialState();
        Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
        });
    },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
