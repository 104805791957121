/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { routeMiddleware } from '@/helpers/routes'

const setRouter = async (routes) => {
  const data = await routes
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: data,
    scrollBehavior (to, from, savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0, behavior: 'smooth'})
        }, 0)
      })
    }
  })
  router.beforeEach(routeMiddleware)
  return router
}
export default setRouter(routes)