import store from "@/store"
import localForage from 'localforage';
// import { Trans } from './translation'

const routerFilter = async (routes) => {
  const temp = await localForage.getItem("auth")
  const myrole = temp?.auth?.user?.role
  const isLoggedIn = store.getters['getisLoggedIn']
  return routes.filter((row) => {
    return ((row.meta.requiresAuth == 1 && isLoggedIn && (row.meta.role.length == 0 || row.meta.role.some(x => x == myrole))) 
    || (row.meta.requiresAuth == 0 && !isLoggedIn) 
    || row.meta.requiresAuth > 1)
  })
}
const routeMiddleware = async (to, from, next) =>{
  await store.restored
  return validateUser(to, next)
}
const validateUser = (to, next) => {
  if (to.meta.requiresAuth == 3 && !store.getters['getisLoggedIn']) {
      return next({
        name: 'Login'
      })
  }
  if (to.matched.some(route => route.meta.beforelogin) && store.getters['getisLoggedIn']) {
      return next({
          name: 'Siakad'
      })
  }
  next()
}

export {
  routerFilter,
  routeMiddleware
}