<template>
    <section class="r-facilities r-ptb-40">
        <div class="r-container">
            <h3 class="r-center-text r-mb-40">Fasilitas</h3>
            <div class="r-flex r-isWrap">
                <div :class="!isMobile ? 'r-column' : 'r-isExpand r-flex'">
                    <div class="r-isRelative r-img-place" :class="!isMobile ? '' : 'r-mlr-8'">
                        <img class="r-position-center" :class="list.length <= 0 ? '' : 'r-isExpand'" v-lazy="imageData(0)" alt="img">
                    </div>
                    <div class="r-isRelative r-img-place" :class="!isMobile ? ' r-mt-12' : 'r-mlr-8'">
                        <img class="r-position-center" :class="list.length <= 1 ? '' : 'r-isExpand'" v-lazy="imageData(1)" alt="img">
                    </div>
                </div>
                <div :class="!isMobile ? 'r-column r-is-two-fifth' : 'r-isExpand r-flex r-mtb-12'">
                    <div class="r-isRelative r-img-place " :class="!isMobile ? 'r-fullHeight' : 'r-mlr-8'">
                        <img class="r-position-center" :class="list.length <= 2 ? '' : 'r-isExpand'" v-lazy="imageData(2)" alt="img">
                    </div>
                </div>
                <div :class="!isMobile ? 'r-column' : 'r-isExpand r-flex'">
                    <div class="r-isRelative r-img-place" :class="!isMobile ? '' : 'r-mlr-8'">
                        <img class="r-position-center" :class="list.length <= 3 ? '' : 'r-isExpand'" v-lazy="imageData(3)" alt="img">
                    </div>
                    <div class="r-isRelative r-img-place" :class="!isMobile ? ' r-mt-12' : 'r-mlr-8'">
                        <img class="r-position-center" :class="list.length <= 4 ? '' : 'r-isExpand'" v-lazy="imageData(4)" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        list: {
            require: false,
            default: []
        }
    },
    computed: {
        ...mapGetters({
            isMobile: 'getIsMobile'
        })
    },
    methods: {
        imageData(index){
            if(this.list.length == 0 || this.list.length <= index) return require('@/assets/image/img.png')
            else return this.list[index].full_path
        }
    }
}
</script>