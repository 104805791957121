<template>
  <MetaTag />
  <Snackbar />
  <router-view />
</template>
<script>
import MetaTag from '@/components/MetaTag'
import Snackbar from '@/components/helper/Snackbar'
import { mapGetters } from 'vuex'
import metatag from '@/mixins/metatag'

export default {
  mixins: [metatag],
  components: {
    MetaTag,
    Snackbar,
  },
  watch: {
    $route(to){
      if(to.meta.title != document.title && to.meta.routeMeta == 1){
        this.setMetaTag({
          title: this.$route.meta.title,
          description: this.$route.meta.description,
          robots: this.$route.meta.robots 
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      getisLoggedIn: 'getisLoggedIn'
    })
  },
  mounted(){
    this.$store.dispatch('setIsMobile',window.innerWidth)
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
  },
  unmounted() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    onResize() {
      this.$store.dispatch('setIsMobile',window.innerWidth)
    },
    async gotoRoute(name){
      try{
        await this.$router.push({name})
      }catch(_){
        await this.$router.push({name: 'NotFound', })
      }
    }
  }
}
</script>