<template>
    <div :class="`r-alert r-alert-${type}`">
        <div class="r-center-flex">
            <!-- <img :src="require(`@/assets/icons/toast/${type}.svg`)" class="r-mr-4" :alt="type"> -->
            <span>
                <slot></slot>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            default: 'info'
        }
    }
}
</script>