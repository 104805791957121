{
  "text": {
    "welcome": "Selamat Datang"
  },
  "metatag": {
    "default": {
      "title": "Judul Default",
      "description": "Deskripsi",
      "keywords": "default"
    }
  }
}