import { mapActions } from 'vuex'

export default {
    data: () => ({
    }),
    methods: {
      ...mapActions({
        snackbar: 'snackbar/showSnackbar',
        setMeta: 'setMetaTag'
      }),
      setMetaTag(meta =  {
        title: 'Sekolah Masehi Kudus 123', 
        description: 'Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Masehi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus)',
        robots: 1}){
        document.title = meta.title
        const r = document.querySelectorAll('meta[name="robots"]');
        r[0].content = meta.robots == 1 ? process.env.VUE_APP_ROBOTS : 'noindex,nofollow'
        this.setMeta(meta)
      }
    }
}