
import { 
    getData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
    edu_level: 'Semua Unit',
    edu_level_id: null,
    status_label: 'Semua Status',
    status: null
}

const itemInit = {
    id: null,
}

const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    request: {...requestInit},
    item: {...itemInit},
    announcements: [],
    subjects: [],
    payments: []
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data,
    getMeta: state => state.meta,
    getRequest: state => state.request,
    getItem: state => state.item,
    getAnnouncements: state => state.announcements || [],
    getSubjects: state => state.subjects || [],
    getPayments: state => state.payments || []
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    async getPaymentsData({
        commit
    }, {edu_level_slot_id, user_id}){
        try {
            const url = `/management/parent/student-payment/${user_id}`
            const response = await getData('api',url, {params: {edu_level_slot_id}});
            commit('SET_RESPONSE', response.data);
            commit('SET_PAYMENTS_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getAnnouncementData({
        commit
    }){
        try {
            const response = await getData('api','/management/user-student/article');
            commit('SET_RESPONSE', response.data);
            commit('SET_ANNOUNCEMENT_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getSubjectsData({
        commit
    }, {edu_level_slot_id = null, user_id = null}){
        try {
            const url = user_id ? `/management/my-children/subject/${user_id}` : '/management/user-student/subject'
            const response = await getData('api',url, {params: {edu_level_slot_id}});
            commit('SET_RESPONSE', response.data);
            commit('SET_SUBJECTS_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_ANNOUNCEMENT_DATA(state, value){
        state.announcements = value
    },
    SET_SUBJECTS_DATA(state, value){
        state.subjects = value
    },
    SET_PAYMENTS_DATA(state, value){
        state.payments = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}