import { routerFilter } from '@/helpers/routes'

function loadAuth(view) {
    return () => import( 
        /* webpackChunkName: "view-[request]" */
        /* webpackMode: "lazy" */
         `@/views/auth/${view}.vue`)
}

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: {
            requiresAuth: 0,
            role: [],
            title: 'Login Siakad Sekolah Masehi Kudus',
            description: 'Masuk ke sistem akademik (siakad) dari sekolah masehi kudus',
            routeMeta: 1,
            robots: 1
        },
        component: loadAuth('Login')
    },
    {
        path: '/pendaftaran',
        name: 'RegistrationForm',
        meta: {
            requiresAuth: 0,
            role: [],
            title: 'Pendaftaran Akun Orang Tua',
            description: 'Pendaftaran akun orang tua untuk menggunakan siakad dan mendaftarkan anak pada sekolah masehi kudus',
            routeMeta: 1,
            robots: 1
        },
        component: loadAuth('RegistrationForm')
    },
    {
        path: '/verify/:token',
        name: 'Verify',
        meta: {
            requiresAuth: 2,
            role: [],
            title: 'Verifikasi Akun',
            description: 'Verifikasi akun',
            routeMeta: 1,
            robots: 0
        },
        component: loadAuth('Verify')
    },
    {
        path: '/lupa-password',
        name: 'ForgetPassword',
        meta: {
            requiresAuth: 0,
            role: [],
            title: 'Lupa Password Akun',
            description: 'Aktifkan kembali password anda',
            routeMeta: 1,
            robots: 0
        },
        component: loadAuth('ForgetPassword')
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            requiresAuth: 1,
            role: [],
            title: 'Logout Akun',
            description: 'Logout akun',
            routeMeta: 1,
            robots: 0
        },
        component: loadAuth('Logout')
    },
    {
        path: '/ppdb',
        name: 'Welcome',
        meta: {
            requiresAuth: 0,
            role: [],
            title: 'Penerimaan Peserta Didik Baru',
            description: 'Penerimaan Peserta Didik Baru',
            routeMeta: 1,
            robots: 0
        },
        component: loadAuth('Welcome'),
    },
]

export default routerFilter(routes)