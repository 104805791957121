
import { 
    getData
} from '@/store/config/method'

const attendanceRequestInit = {
    status: null,
    status_label: 'Semua Status',
    date_start: null,
    date_end: null,
}
const agendaRequestInit = {
    filter: 'desc',
    filter_label: 'Terbaru - Terlama',
    search: null,
    page: 1
}
const initialState = () => ({
    message: null,
    status: 0,
    subject: {},
    attendanceRequest: {...attendanceRequestInit},
    agendaRequest: {...agendaRequestInit},
    attendances: [],
    classHistories: [],
    agenda: [],
    agendaMeta: {},
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getSubject: state => state.subject,
    getAttendances: state => state.attendances || [],
    getAttendanceRequest: state => state.attendanceRequest,
    getAgendaRequest: state => state.agendaRequest,
    getClassHistories: state => state.classHistories || [],
    getAgenda: state => state.agenda || [],
    getAgendaMeta: state => state.agendaMeta || []
}

const actions = {
    resetAttendanceRequest({commit}){
        commit('SET_ATTENDANCE_REQUEST', {...attendanceRequestInit})
    },
    resetAgendaRequestInit({commit}){
        commit('SET_AGENDA_REQUEST', {...agendaRequestInit})
    },
    async getClassHistoriesData({
        commit
    }, user_id = null){
        try {
            const url = user_id
                ? `/class-history/${user_id}`
                : `/class-history`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_CLASS_HISTORIES_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getSubjectData({
        commit
    }, classroom_subject_id){
        try {
            const url = `/management/user-student/current-subject/${classroom_subject_id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_SUBJECT_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getAttendancesData({
        commit, state
    }, {classroom_subject_id, user_id = null}){
        try {
            const url = user_id 
                ? `/management/my-children/${user_id}/attendance/${classroom_subject_id}`
                : `/management/user-student/attendance/${classroom_subject_id}`
            const response = await getData('api',url, {params: state.attendanceRequest});
            commit('SET_RESPONSE', response.data);
            commit('SET_ATTENDANCES_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getAgendaData({
        commit, state
    }, {classroom_subject_id, user_id = null}){
        try {
            const url = user_id 
                ? `/management/my-children/${user_id}/agenda/${classroom_subject_id}`
                : `/management/user-student/agenda/${classroom_subject_id}`
            const response = await getData('api',url, {params: state.agendaRequest});
            commit('SET_RESPONSE', response.data);
            commit('SET_AGENDA_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_SUBJECT_DATA(state, value) {
        state.subject = value
    },
    SET_ATTENDANCE_REQUEST(state, value) {
        state.attendanceRequest = value
    },
    SET_AGENDA_REQUEST(state, value) {
        state.agendaRequest = value
    },
    SET_ATTENDANCES_DATA(state, response) {
        state.attendances = response.data
    },
    SET_AGENDA_DATA(state, response) {
        const reqPage = state.agendaRequest.page
        let agenda = response.data
        if(reqPage > 1){
            agenda = [...state.agenda, ...response.data]
        }
        state.agenda = agenda
        state.agendaMeta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_CLASS_HISTORIES_DATA(state, value) {
        state.classHistories = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}