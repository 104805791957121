<template>
    <nav class="r-navbar r-navbar-scroll r-isSticky">
        <div class="r-isRelative r-keep-view">
            <div class="r-center-flex r-container">
                <SiakadLogo></SiakadLogo>
                <div class="r-center-flex r-ml-auto" >
                    <router-link class="r-isExpand r-center-flex r-error-text" :to="{name: 'Logout'}" >
                         <img 
                            class="r-mr-4"
                            :src="require('@/assets/icons/error/logout.svg')" alt="logout">
                        <span >Keluar</span>
                    </router-link>  
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex'
import SiakadLogo from '@/components/helper/SiakadLogo'

export default {
    computed: {
        ...mapGetters({
            isMobile: 'getIsMobile',
            isLoggedIn: 'getisLoggedIn'
        })
    },
    components: {
        SiakadLogo
    },
   
}
</script>