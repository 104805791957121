
import { 
    getData
} from '@/store/config/method'


const initialState = () => ({
    message: null,
    status: 0,
    categories: [],
    location: [],
    religion: [],
    job: [],
    income: [],
    transportation: [],
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getCategories: state => state.categories || [],
    getLocation: state => state.location || [],
    getReligion: state => state.religion || [],
    getJob: state => state.job || [],
    getIncome: state => state.income || [],
    getTransportation: state => state.transportation || []
}

const actions = {
    async getTransportationData({
        commit
    }){
        try {
            const url = `/transportation`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_TRANSPORTATION_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getLocationData({
        commit
    }){
        try {
            const url = `/location`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_LOCATION_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getJobData({
        commit
    }){
        try {
            const url = `/job`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_JOB_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getIncomeData({
        commit
    }){
        try {
            const url = `/income`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_INCOME_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getReligionData({
        commit
    }){
        try {
            const url = `/religion`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_RELIGION_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getCategoriesData({
        commit
    }){
        try {
            const response = await getData('api','/category');
            commit('SET_RESPONSE', response.data);
            commit('SET_CATEGORIES_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_LOCATION_DATA(state, value) {
        state.location = value
    },
    SET_RELIGION_DATA(state, value) {
        state.religion = value
    },
    SET_JOB_DATA(state, value) {
        state.job = value
    },
    SET_INCOME_DATA(state, value) {
        state.income = value
    },
    SET_TRANSPORTATION_DATA(state, value) {
        state.transportation = value
    },
    SET_CATEGORIES_DATA(state, value) {
        state.categories = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}